.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#toast-container-main {
  position: fixed;
  top: 1em;
  right: 1em;
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  width: 350px;
}

#toast-container-main>div {
  width: 100%;
  margin-bottom: 10px;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
  animation: slide-in 0.5s;
  transition: all 0.9s ease;    
}

.toast-header {
  font-weight: 500;
  padding: 10px;
  display: flex;
  height: 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  -moz-border-radius: 0px;
  -webkit-border-radius: 3px 3px 0px 0px;
  border-radius: 10px 10px 0px 0px;
  font-size: 20px;
  font-weight: bolder;
}

.toast-body {
  font-size: 18px;
  font-weight: 900;
  min-height: 40px;
  padding: 10px;
  border-radius: 0px 0px 10px 10px;
}

@keyframes slide-in {
  from {
    transform: translateX(30px);
    opacity: 0;
  }

  to {
    transform: translateX(0px);
    opacity: 1;
  }
}